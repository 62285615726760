import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useViewer } from '@hs-baumappe/web-auth';
import { Box, Dialog, Flex, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { LayoutLoading } from '@hs-baumappe/frontend-kit';
import { useTranslation } from 'react-i18next';
import { ErrorRenderer, MissingScopeError, NotFoundError } from '@hs-baumappe/redkit';

import useCustomFileDocumentDetail from '../hooks/useCustomFileDocument';
import ErrorState from '../../../containers/ErrorState';
import DocumentEditTitleForm from './DocumentEditTitleForm/DocumentEditTitleForm';
import { projectsRoutes } from '../../routes';
import documentRoutes from '../documentRoutes';
import { DocumentLocationState, DocumentRouteParams } from '../document.route.types';
import { DocumentEditTitleFormValues } from './DocumentEditTitleForm/values';
import { navigateToLocation } from '../../routes.utils';
import CustomFileUpdateMutation from './graphql/DocumentUpdate.mutation';

export default function DocumentEditTitle(): JSX.Element | null {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const [routeModalOpen, setRouteModalOpen] = useState(true);

  const { documentId } = useParams<DocumentRouteParams>();
  const location = useLocation<DocumentLocationState>();
  const navigate = useNavigate();

  const { data, loading, error } = useCustomFileDocumentDetail(documentId);
  const [renameDocument, { error: renameError }] = useMutation(CustomFileUpdateMutation);

  const handleDialogClose = useCallback(() => {
    if (error) {
      navigate(projectsRoutes.detail.generatePath({}));
      return;
    }

    navigateToLocation({
      navigate,
      state: location.state,
      fallbackPath: documentRoutes.manipulate.generatePath({ documentId }),
    });
  }, [navigate, location.state, error, documentId]);

  async function handleSubmit(value: DocumentEditTitleFormValues) {
    if (loading) {
      return;
    }

    const { data: renameData } = await renameDocument({
      variables: {
        input: {
          id: documentId,
          newName: value.name,
        },
      },
    });

    if (!renameData) {
      return;
    }

    navigate(documentRoutes.manipulate.generatePath({ documentId }));
  }

  function handleDialogRequestClose() {
    setRouteModalOpen(false);
  }

  function renderContent() {
    if (loading && !data) {
      return <LayoutLoading />;
    }

    if (error) {
      return <ErrorRenderer apolloError={error} />;
    }

    if (!data?.document || !viewer || !data.document.viewerCanRename) {
      return <NotFoundError />;
    }

    const { document } = data;

    const updateDocument = viewer.scopes.updateCustomFileDocument;
    if (!updateDocument.isPermitted) {
      return <MissingScopeError missingScopes={updateDocument.missingScopeNames} />;
    }

    return (
      <Box stylex={{ paddingTop: 'large' }}>
        <DocumentEditTitleForm initialValues={{ name: document.name }} onSubmit={handleSubmit} />
      </Box>
    );
  }

  return (
    <Dialog
      open={routeModalOpen}
      onRequestClose={handleDialogRequestClose}
      onClose={handleDialogClose}
      size="fullscreen"
      animationType="slide-up"
    >
      {renameError && <ErrorState error={renameError} />}

      <Box className="container" stylex={{ width: '100%', paddingEnds: 'large' }}>
        <Flex alignItems="center">
          <Box stylex={{ flexGrow: '1' }}>
            {data?.document && (
              <Text variant="title-small" component="h2" stylex={{ marginBottom: '0' }}>
                {t('customFile.editTitleModal.editTitle', {
                  documentTitle: data?.document.name,
                })}
              </Text>
            )}
          </Box>

          <IconButton icon={<Icon name="close" />} onClick={handleDialogRequestClose} />
        </Flex>

        <Box>{renderContent()}</Box>
      </Box>
    </Dialog>
  );
}
