import { gql } from '@apollo/client';

const CustomFileDocumentFragment = gql`
  fragment CustomFileDocument on DriveDocument {
    id

    name
    status
    mimeType
    size

    download {
      name
      url
    }

    manipulator
    viewerCanManipulate

    viewerCanArchive
    viewerCanRemove
    viewerCanRename
    viewerCanSendEmail
    viewerCanUpdate
  }
`;

export default CustomFileDocumentFragment;
