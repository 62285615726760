import { gql } from '@apollo/client';

const ViewerDrivePreferencesFragment = gql`
  fragment ViewerDrivePreferences on ViewerDrivePreferences {
    viewPreferences {
      baumappe
      bauarchiv
    }
  }
`;

export default ViewerDrivePreferencesFragment;
