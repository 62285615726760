import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Flex, Radio, RadioGroup, Stack, Text, Textarea } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import cx from 'classnames';
import stylex from '@hs-baumappe/legacy-stylex';
import AcceptanceReportFormSection from '../components/AcceptanceReportFormSection';
import { Acceptance, AcceptanceReportFormValues } from '../AcceptanceReportFormValues';
import { MAX_ACCEPTANCE_DESCRIPTION_TEXT_LENGTH } from '../AcceptanceReportForm.form';

export default function AcceptSection(): JSX.Element {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<AcceptanceReportFormValues>();

  const remainingDescriptionTextCharacters =
    MAX_ACCEPTANCE_DESCRIPTION_TEXT_LENGTH - (values.acceptanceDescriptionText?.length ?? 0);

  return (
    <AcceptanceReportFormSection title={t('acceptanceReport.acceptance.title')}>
      <RadioGroup
        value={values.acceptance}
        onChange={(value) => setFieldValue('acceptance', value)}
        className={cx(stylex.create({ marginTop: 'medium', gap: 'small-3x' }))}
      >
        <Flex>
          <Radio
            value={Acceptance.FULL}
            label={t('acceptanceReport.acceptance.fullAcceptance.placeholder')}
            disableExtraPressableArea
          />
        </Flex>

        <Flex>
          <Radio
            value={Acceptance.PARTIAL}
            label={t('acceptanceReport.acceptance.partialAcceptance.placeholder')}
            disableExtraPressableArea
          />
        </Flex>
      </RadioGroup>

      {values.acceptance === Acceptance.PARTIAL && (
        <Stack gap="small-x" stylex={{ marginTop: 'small' }}>
          <Text color="primary">{t('acceptanceReport.acceptance.info')}</Text>

          <Stack gap="small-4x">
            <FormikFormField<typeof Textarea>
              control={Textarea}
              name="acceptanceDescriptionText"
              placeholder={t('acceptanceReport.acceptance.descriptionText.placeholder')}
              minRows={4}
              maxRows={12}
              maxLength={MAX_ACCEPTANCE_DESCRIPTION_TEXT_LENGTH}
            />
            <Text
              stylex={{ alignSelf: 'flex-end' }}
              variant="label-small"
              color={remainingDescriptionTextCharacters === 0 ? 'error' : 'muted'}
            >
              {t('acceptanceReport.acceptance.descriptionText.remainingCharacters', {
                remainingCharacters: remainingDescriptionTextCharacters,
              })}
            </Text>
          </Stack>

          <FormikFormField
            name="acceptanceDescriptionReference"
            label={t('acceptanceReport.acceptance.descriptionReference.label')}
            placeholder={t('acceptanceReport.acceptance.descriptionReference.placeholder')}
          />
        </Stack>
      )}
    </AcceptanceReportFormSection>
  );
}
