import routes from '../routes';
import { route } from '../routes.utils';
import { DocumentRouteParams } from './document.route.types';

export default {
  detail: route<DocumentRouteParams>(routes.document.path),
  archive: route<DocumentRouteParams>(`${routes.document.path}/archive`),
  manipulate: route<DocumentRouteParams>(`${routes.document.path}/manipulate`),
  sendEmail: route<DocumentRouteParams>(`${routes.document.path}/send-email`),
  editTitle: route<DocumentRouteParams>(`${routes.document.path}/edit-title`),
};
