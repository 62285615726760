import { ReactNode, useEffect } from 'react';
import { Box, Button, Checkbox, Flex, FormHelperText, Icon, Stack } from '@hs-baumappe/legacy-ui';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AcceptanceReportEmployeesQuery from './graphql/AcceptanceReportEmployees.query';
import AcceptanceReportFormLayout from './components/AcceptanceReportFormLayout';
import InformationSection from './InformationSection';
import AttendeeSection from './AttendeeSection';
import AcceptSection from './AcceptSection';
import DefectSection from './DefectSection';
import ImageAttachmentsSection from './ImageAttachmentsSection';
import { AcceptanceReportFormValues, Defect } from './AcceptanceReportFormValues';
import { LaunchDarklyFlagSet } from '../../../../launchdarkly/launchDarklyFlagSet';
import {
  createAcceptanceReportFormValidationSchema,
  getAcceptanceReportFormInitialValues,
} from './AcceptanceReportForm.form';

interface AcceptanceReportFormProps {
  initialValues?: Partial<AcceptanceReportFormValues>;
  projectId: string;
  contactPeople: string[];
  professions: string[];
  draft: boolean;
  loading?: boolean;
  submitButtonLabel: ReactNode;
  onSubmit: (
    values: AcceptanceReportFormValues,
    formikHelpers: FormikHelpers<AcceptanceReportFormValues>,
  ) => void;
  onDirtyStateChange?: (dirty: boolean) => void;
}

export default function AcceptanceReportForm({
  initialValues,
  projectId,
  draft,
  contactPeople,
  professions,
  loading,
  submitButtonLabel,
  onSubmit,
  onDirtyStateChange,
}: AcceptanceReportFormProps): JSX.Element {
  const { t } = useTranslation();
  const { acceptanceReportNegativeFlow } = useFlags<LaunchDarklyFlagSet>();

  const { data } = useQuery(AcceptanceReportEmployeesQuery, {
    variables: {
      projectId,
    },
  });

  const formik = useFormik<AcceptanceReportFormValues>({
    onSubmit,
    validationSchema: createAcceptanceReportFormValidationSchema(t, acceptanceReportNegativeFlow),
    initialValues: getAcceptanceReportFormInitialValues(initialValues),
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });
  const { values, errors, dirty } = formik;

  useEffect(() => {
    if (onDirtyStateChange) {
      onDirtyStateChange(dirty);
    }
  }, [dirty, onDirtyStateChange]);

  function shouldDefectAttachImageToPdfCheckboxDisabled() {
    const uploadedDefectMetaImages = values.defectMeta.filter(
      (defectMetaImage) => !!defectMetaImage && defectMetaImage.imageId,
    );
    const uploadedDefectMetaImagesCount = uploadedDefectMetaImages.length;

    return uploadedDefectMetaImagesCount === 0;
  }

  const employeeNames = data?.employeesForProject
    ? data.employeesForProject.map((employee) => employee.name)
    : [];

  return (
    <FormikProvider value={formik}>
      <Box component={Form} stylex={{ paddingBottom: 'large' }}>
        <AcceptanceReportFormLayout
          information={<InformationSection professions={professions} draft={draft} />}
          attendee={<AttendeeSection contactPeople={contactPeople} employeeNames={employeeNames} />}
          accept={<AcceptSection />}
          defect={<DefectSection projectId={projectId} />}
          imageAttachments={<ImageAttachmentsSection projectId={projectId} />}
        >
          <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
            <Button
              type="submit"
              color="primary"
              endIcon={<Icon name="add" />}
              fullWidth
              loading={loading}
            >
              {submitButtonLabel}
            </Button>
            {values.defect === Defect.HAS_DEFECT && (
              <Stack gap="small-3x" stylex={{ marginTop: 'small-x' }}>
                <Flex>
                  <Checkbox
                    name="attachImagesToPdf"
                    label={t('acceptanceReport.defect.attachImagesToLog')}
                    disabled={shouldDefectAttachImageToPdfCheckboxDisabled()}
                    checked={values.attachImagesToPdf}
                    onChange={formik.handleChange}
                    disableExtraPressableArea
                  />
                </Flex>

                {errors.attachImagesToPdf && (
                  <FormHelperText error>{errors.attachImagesToPdf}</FormHelperText>
                )}
              </Stack>
            )}
          </Box>
        </AcceptanceReportFormLayout>
      </Box>
    </FormikProvider>
  );
}
