import { Form, FormikProvider, useFormik } from 'formik';
import { Box, Button, Icon } from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import { useTranslation } from 'react-i18next';

import { documentEditTitleInitialValues, validationSchema } from './form';
import { DocumentEditTitleFormValues } from './values';

interface DocumentEditTitleFormProps {
  initialValues?: Partial<DocumentEditTitleFormValues>;
  onSubmit: (documentName: DocumentEditTitleFormValues) => void;
}

export default function DocumentEditTitleForm({
  initialValues,
  onSubmit,
}: DocumentEditTitleFormProps): JSX.Element {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: documentEditTitleInitialValues(initialValues),
    onSubmit,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
  });

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--sm-8">
            <Box className="row">
              <Box className="col col--sm-6">
                <FormikFormField name="name" label={t('customFile.editTitleModal.input.title')} />
              </Box>
            </Box>
          </Box>

          <Box className="col col--sm-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button type="submit" color="primary" fullWidth endIcon={<Icon name="save" />}>
                {t('customFile.editTitleModal.saveButton')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
