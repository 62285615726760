import { Viewer } from '@hs-baumappe/web-auth';
import { Box, Button, Flex, FlexGrid, FlexGridColumn, Icon, Text } from '@hs-baumappe/legacy-ui';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Link as RouteLink, useNavigate } from 'react-router-dom';

import useDriveViewerPreferences from './hooks/useDriveViewerPreference';
import { AppModuleNavigationProject } from '../app-modules/graphql/__generated__/AppModuleNavigationProject.fragment';
import { ProjectDetailLocationState } from '../ProjectDetail/ProjectDetail.route.types';
import { riskAssessmentRoutes } from '../../RiskAssessment/riskAssessmentRoutes';
import { projectRoutes } from '../../routes';
import { isRiskAssessmentSignable } from '../../RiskAssessment';
import i18n from '../../../i18n';

import styles from './RiskAssessmentMissingSignatureNotification.module.scss';

type ProjectRiskAssessmentRegulation = NonNullable<
  AppModuleNavigationProject['riskAssessmentRegulation']
>;

interface RiskAssessmentMissingSignatureNotificationProps {
  projectId: string;
  folderId: string;
  riskAssessmentRegulation: ProjectRiskAssessmentRegulation;
  scopes: Viewer['scopes'];
  dismiss: () => void;
}

const MAX_VISIBLE_RISK_ASSESSMENT_COUNT = 5;

export function RiskAssessmentMissingSignatureNotification({
  projectId,
  folderId,
  riskAssessmentRegulation,
  scopes,
  dismiss,
}: RiskAssessmentMissingSignatureNotificationProps): JSX.Element {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'projectDetail.riskAssessment.missingSignature.notification',
  });
  const { preferences, updateViewPreference } = useDriveViewerPreferences(projectId);
  const navigate = useNavigate();

  const { riskAssessmentsWithMissingSignatures: riskAssessments } = riskAssessmentRegulation;
  const visibleRiskAssessments = riskAssessments.slice(0, MAX_VISIBLE_RISK_ASSESSMENT_COUNT);

  const signPermitted = scopes.signRiskAssessmentDocument.isPermitted;
  const signable = isRiskAssessmentSignable({ riskAssessment: riskAssessments[0], signPermitted });

  const viewDocumentationFolderPermitted =
    scopes.viewConstructionSiteDocumentationFolder.isPermitted;

  async function goToDocumentationFolder() {
    if (!viewDocumentationFolderPermitted) return;

    if (!preferences.viewPreferences.baumappe) {
      await updateViewPreference({ baumappe: true });
    }

    navigate(projectRoutes.driveWithFolder.generatePath({ folderId, projectId }), {
      state: { scrollToDrive: true } satisfies ProjectDetailLocationState,
    });
  }

  return (
    <>
      <Flex gap="medium">
        <Box stylex={{ paddingTop: 'small-2x' }}>
          <Icon name="warning" color="white" size="24" />
        </Box>
        <Box stylex={{ paddingTop: 'small-2x' }}>
          <Text component="div" variant="title-medium" color="white">
            {t('title', { count: riskAssessments.length })}
          </Text>

          <Text variant="label-large" color="yellow-900">
            {t('message', { documentName: riskAssessments[0].name, count: riskAssessments.length })}
          </Text>

          {riskAssessments.length > 1 && (
            <ul className={styles['risk-assessment-missing-signature-notification__list']}>
              {visibleRiskAssessments.map((riskAssessment) => (
                <li key={riskAssessment.id}>
                  <Text>{riskAssessment.name}</Text>
                </li>
              ))}
              {riskAssessments.length > MAX_VISIBLE_RISK_ASSESSMENT_COUNT && (
                <li>
                  <Text>...</Text>
                </li>
              )}
            </ul>
          )}

          <FlexGrid stylex={{ marginEnds: 'small-2x' }}>
            <FlexGridColumn sm={6}>
              <Button endIcon={<Icon size={20} name="update" />} fullWidth onClick={dismiss}>
                {t('dismissButtonText')}
              </Button>
            </FlexGridColumn>
            <FlexGridColumn sm={6}>
              {riskAssessments.length === 1 && signable && (
                <Button
                  component={RouteLink}
                  to={riskAssessmentRoutes.sign.generatePath({
                    riskAssessmentId: riskAssessments[0].id,
                  })}
                  endIcon={<Icon size={16} name="border-color" />}
                  fullWidth
                >
                  {t('signButtonText')}
                </Button>
              )}

              {riskAssessments.length > 1 && viewDocumentationFolderPermitted && (
                <Button
                  onClick={goToDocumentationFolder}
                  endIcon={<Icon name="forward" />}
                  fullWidth
                >
                  {t('goToFolderButtonText')}
                </Button>
              )}
            </FlexGridColumn>
          </FlexGrid>
        </Box>
      </Flex>
    </>
  );
}

export default function RiskAssessmentMissingSignatureNotificationWithTranslation(
  props: RiskAssessmentMissingSignatureNotificationProps,
): JSX.Element {
  return (
    <I18nextProvider i18n={i18n}>
      <RiskAssessmentMissingSignatureNotification {...props} />
    </I18nextProvider>
  );
}
