import { ReactNode, useRef, useState } from 'react';
import { Box, Button, FlexGrid, FlexGridColumn, Icon, uniqueId } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import {
  createSignatureFormValidationSchema,
  SignatureForm as BaseSignatureForm,
  SignatureFormProps as BaseSignatureFormProps,
} from '@hs-baumappe/forms';
import yup from '../../yup';

type AllowedSignatureFormProps = Pick<
  BaseSignatureFormProps,
  'initialValues' | 'lastSignature' | 'readOnly' | 'onSubmit' | 'onDirtyStateChange'
>;

interface SignatureFormProps extends AllowedSignatureFormProps {
  nameInputLabel?: string;
  nameInputPlaceholder?: string;
  signatureInputLabel?: string;
  submitButtonText?: string;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  names?: string[];
  resignable?: boolean;
  loading?: boolean;
}

export default function SignatureForm({
  initialValues,
  nameInputLabel,
  nameInputPlaceholder,
  signatureInputLabel,
  submitButtonText,
  leftSection,
  rightSection,
  names,
  resignable,
  loading,
  readOnly: readOnlyProp,
  ...otherProps
}: SignatureFormProps): JSX.Element {
  const { t } = useTranslation();

  const id = useRef(uniqueId('signature-form-'));
  const [readOnly, setReadOnly] = useState(readOnlyProp);

  return (
    <>
      {leftSection && <Box stylex={{ marginBottom: 'small-x' }}>{leftSection}</Box>}

      <FlexGrid>
        <FlexGridColumn sm={8}>
          <BaseSignatureForm
            id={id.current}
            readOnly={readOnly}
            initialValues={{ date: format(new Date(), 'dd.MM.yyyy'), ...initialValues }}
            validationSchema={createSignatureFormValidationSchema(yup)}
            nameInputProps={{
              label: nameInputLabel || t('signatureForm.name.label'),
              placeholder: nameInputPlaceholder || t('signatureForm.name.placeholder'),
              suggestions: names,
            }}
            dateInputProps={{
              label: t('signatureForm.date.label'),
              placeholder: t('signatureForm.date.placeholder'),
              disabled: true,
            }}
            signatureInputProps={{
              label: signatureInputLabel || t('signatureForm.signature.label'),
            }}
            useLastSignatureCheckboxProps={{
              label: t('signatureForm.useLastSignatureHelperText'),
            }}
            onSignaturePadClear={readOnly && resignable ? () => setReadOnly(false) : undefined}
            {...otherProps}
          />
        </FlexGridColumn>

        <FlexGridColumn sm={4}>
          <Button
            form={id.current}
            type="submit"
            endIcon={<Icon name="save" />}
            color="primary"
            loading={loading}
            fullWidth
            stylex={{ marginTop: 'small-x' }}
          >
            {submitButtonText || t('signatureForm.submitButtonText')}
          </Button>

          <Box stylex={{ marginTop: 'small-x' }}>{rightSection}</Box>
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
