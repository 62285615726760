import { gql, TypedDocumentNode } from '@apollo/client';
import AppModuleNavigationProjectFragment from '../../../graphql/AppModuleNavigationProject.fragment';
import {
  AppModuleProjectDetail,
  AppModuleProjectDetailVariables,
} from './__generated__/AppModuleProjectDetail.query';

const AppModuleProjectDetailQuery: TypedDocumentNode<
  AppModuleProjectDetail,
  AppModuleProjectDetailVariables
> = gql`
  query AppModuleProjectDetail($projectId: ID!, $projectDetailRiskAssessmentStatus: Boolean!) {
    project(id: $projectId) {
      ...AppModuleNavigationProject
    }
  }

  ${AppModuleNavigationProjectFragment}
`;

export default AppModuleProjectDetailQuery;
