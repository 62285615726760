import { gql } from '@apollo/client';

const AppModuleNavigationProjectFragment = gql`
  fragment AppModuleNavigationProject on Project {
    id
    draft

    siv {
      id

      hasBillableOperations
    }

    riskAssessmentRegulation @include(if: $projectDetailRiskAssessmentStatus) {
      status
      riskAssessmentsWithMissingSignatures {
        id
        name

        signatures {
          id
          type
        }

        viewerCanSign
      }
    }

    drive {
      id

      mimeTypeFolderMap {
        folder {
          id
          name
        }
        mimeType
      }
    }
  }
`;

export default AppModuleNavigationProjectFragment;
