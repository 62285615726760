import { useMutation, useQuery } from '@apollo/client';
import DriveViewerPreferenceQuery from './graphql/DriveViewerPreferences.query';
import UpdateViewerDrivePreferencesMutation from './graphql/UpdateViewerDrivePreferences.mutation';
import { ViewerDrivePreferences } from './graphql/__generated__/ViewerDrivePreferences.fragment';

export type DriveViewPreferences = Omit<
  ViewerDrivePreferences['viewPreferences'],
  'id' | '__typename'
>;

interface UseDriveViewerPreferenceResponse {
  preferences: ViewerDrivePreferences;
  updateViewPreference: (next: Partial<DriveViewPreferences>) => Promise<void>;
  loading: boolean;
}

const DEFAULT_VIEWER_DRIVE_PREFERENCES: ViewerDrivePreferences = {
  viewPreferences: {
    bauarchiv: true,
    baumappe: true,
    __typename: 'DriveViewPreferences',
  },
  __typename: 'ViewerDrivePreferences',
};

export default function useDriveViewerPreferences(
  projectId: string,
): UseDriveViewerPreferenceResponse {
  const [drivePreferenceUpsert] = useMutation(UpdateViewerDrivePreferencesMutation);

  const { data, loading } = useQuery(DriveViewerPreferenceQuery, {
    fetchPolicy: 'cache-first',
    variables: {
      projectId,
    },
  });

  const drivePreference = data?.viewer.drivePreferences || DEFAULT_VIEWER_DRIVE_PREFERENCES;

  async function updateViewPreference(next: Partial<DriveViewPreferences>): Promise<void> {
    const viewPreferences = {
      bauarchiv: next.bauarchiv ?? drivePreference.viewPreferences.bauarchiv,
      baumappe: next.baumappe ?? drivePreference.viewPreferences.baumappe,
    };

    await drivePreferenceUpsert({
      variables: {
        input: {
          viewPreferences,
        },
      },
      optimisticResponse: {
        drivePreferenceUpsert: {
          ...drivePreference,
          id: data?.viewer.drivePreferences?.id || 'optimistic-id',
          viewPreferences: {
            ...viewPreferences,
            __typename: 'DriveViewPreferences',
          },
          __typename: 'ViewerDrivePreferences',
        },
      },
    });
  }

  return {
    preferences: drivePreference,
    loading,
    updateViewPreference,
  };
}
