import { FormikFormField } from '@hs-baumappe/forms';
import { FlexGrid, FlexGridColumn } from '@hs-baumappe/legacy-ui';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import FormikProfessionsField from '../../../../../../components/FormikProfessionsField';
import { ObstructionFormValues } from '../../ObstructionForm.form';

interface ObstructionFormDetailSectionProps {
  professions: string[];
}

export default function ObstructionFormDetailSection({
  professions,
}: ObstructionFormDetailSectionProps): JSX.Element {
  const { t } = useTranslation();
  const { values } = useFormikContext<ObstructionFormValues>();

  return (
    <>
      <FlexGrid>
        <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            name="projectNumber"
            label={t('obstructionForm.projectNumber.label')}
            placeholder={t('obstructionForm.fact.title.placeholder')}
            disabled
          />
        </FlexGridColumn>

        <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            name="clientName"
            label={t('obstructionForm.clientName.label')}
            placeholder={t('obstructionForm.clientName.placeholder')}
          />
        </FlexGridColumn>
      </FlexGrid>

      <FlexGrid>
        <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
          <FormikFormField
            type="date"
            name="date"
            label={t('obstructionForm.date.label')}
            placeholder={t('obstructionForm.date.placeholder')}
            value={values.date || ''}
          />
        </FlexGridColumn>

        <FlexGridColumn sm={6} stylex={{ marginTop: 'small-x' }}>
          <FormikProfessionsField name="professions" professions={professions} />
        </FlexGridColumn>
      </FlexGrid>
    </>
  );
}
