import { gql } from '@apollo/client';

const CustomFileUpdateMutation = gql`
  mutation CustomFileUpdate($input: DriveDocumentRenameInput!) {
    driveDocumentRename(input: $input) {
      id
      name
    }
  }
`;

export default CustomFileUpdateMutation;
