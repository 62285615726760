import { ReactNode, useState } from 'react';
import { useField } from 'formik';
import { FormikFormField } from '@hs-baumappe/forms';
import {
  Box,
  FileViewer,
  FileViewerToolbar,
  Flex,
  Icon,
  IconButton,
  Text,
} from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import { SchemaOf } from 'yup';
import yup from '../../../yup';

import './o-image-upload-with-label.scss';

interface ImageUploadWithLabelProps {
  name: string;
  inputContainerClassName?: string;
  labelInputPlaceholder?: string;
  renderRemoveButton?: ReactNode;
  originalImageUrl?: string;
  loading?: boolean;
  showRemoveButton?: boolean;
  onRequestRemove?: () => void;
  disabled?: boolean;
  previewSection: (handleShowOriginalImageOpen: () => void) => ReactNode;
}

export interface ImageUploadWithLabelFormValues {
  label?: string;
  imageId?: string;
  imageUrl?: string;
  imageThumbnailUrl?: string;
  orderId: string;
  file?: File;
}

type ImageUploadWithLabelFormValuesSchemaTypes = Pick<
  ImageUploadWithLabelFormValues,
  'label' | 'imageId' | 'orderId'
>;

export const imageUploadWithLabelValidationSchema: SchemaOf<ImageUploadWithLabelFormValuesSchemaTypes> =
  yup.object().shape({
    label: yup.string().max(255),
    imageId: yup.string().when('label', {
      is: (label: unknown) => !!label,
      then: yup.string().required(),
    }),
    orderId: yup.string().required(),
  });

export function createEmptyImageWithLabel(
  overwrites?: Partial<ImageUploadWithLabelFormValues>,
): ImageUploadWithLabelFormValues {
  return {
    orderId: Math.random().toString(36).substr(2),
    label: '',
    imageId: '',
    ...overwrites,
  };
}

export default function ImageUploadWithLabel({
  name,
  inputContainerClassName,
  labelInputPlaceholder,
  originalImageUrl,
  loading,
  showRemoveButton,
  onRequestRemove,
  disabled,
  previewSection,
  ...otherProps
}: ImageUploadWithLabelProps): JSX.Element {
  const [showOriginalImage, setShowOriginalImage] = useState<boolean>(false);
  const [labelField, labelFieldMeta] = useField<ImageUploadWithLabelFormValues['label']>(
    `${name}.label`,
  );
  const [, imageIdFieldMeta] = useField<ImageUploadWithLabelFormValues['imageId']>(
    `${name}.imageId`,
  );

  return (
    <>
      <Flex className="o-image-upload-with-label" gap="small-x">
        <Flex className={inputContainerClassName} gap="small-x" stylex={{ flexGrow: '1' }}>
          <Box>{previewSection(() => setShowOriginalImage(true))}</Box>

          <FormikFormField
            name={labelField.name}
            className={cx(stylex.create({ flexGrow: '1' }))}
            placeholder={labelInputPlaceholder}
            value={labelField.value || ''}
            disabled={disabled}
            {...otherProps}
          />
        </Flex>

        <Box className="o-image-upload-with-label__remove-section-container">
          {showRemoveButton && !loading && (
            <IconButton
              data-testid="remove-entry-button"
              type="button"
              size="small"
              icon={<Icon name="close" />}
              onClick={onRequestRemove}
              disabled={disabled}
            />
          )}
        </Box>
      </Flex>

      {imageIdFieldMeta.error && (
        <Box>
          <Text variant="body-small" color="error">
            {imageIdFieldMeta.error}
          </Text>
        </Box>
      )}

      <FileViewer
        open={showOriginalImage}
        onRequestClose={() => setShowOriginalImage(false)}
        toolbar={
          <FileViewerToolbar
            leftSection={
              <IconButton
                icon={<Icon name="keyboard-arrow-left" />}
                color="white"
                onClick={() => setShowOriginalImage(false)}
              />
            }
            title={labelFieldMeta.value}
          />
        }
      >
        <Flex justifyContent="center">
          <img src={originalImageUrl} alt={labelFieldMeta.value} />
        </Flex>
      </FileViewer>
    </>
  );
}
