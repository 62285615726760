import { useEffect } from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Box } from '@hs-baumappe/legacy-ui';
import stylex from '@hs-baumappe/legacy-stylex';
import cx from 'classnames';
import ImageUploadWithLabel from '../../../../../../containers/forms/ImageUploadWithLabel';
import { DefectImageFormValues } from '../../AcceptanceReportFormValues';
import DefectSectionUploadImageMutation from './graphql/DefectSectionUploadImageMutation';
import {
  DefectSectionUploadImage,
  DefectSectionUploadImageVariables,
} from './graphql/__generated__/DefectSectionUploadImageMutation';
import ClickableImageThumbnail from '../../../../../../components/ClickableImageThumbnail';
import DefectSectionAttachmentMenu from '../DefectSectionAttachmentMenu';

interface DefectSectionUploadImageItemProps {
  name: string;
  disabled?: boolean;
  showRemoveButton?: boolean;
  onRemoveButtonClick: () => void;
  onUploadImageButtonClick: () => void;
  onSelectImageButtonClick: () => void;
}

export default function DefectSectionUploadImageItem({
  name,
  disabled,
  showRemoveButton,
  onRemoveButtonClick,
  onUploadImageButtonClick,
  onSelectImageButtonClick,
}: DefectSectionUploadImageItemProps): JSX.Element {
  const { t } = useTranslation();
  const [upload, { loading, data }] = useMutation<
    DefectSectionUploadImage,
    DefectSectionUploadImageVariables
  >(DefectSectionUploadImageMutation);
  const [imageIdField, imageIdFieldMeta, imageIdFieldHelpers] = useField<
    DefectImageFormValues['imageId']
  >(`${name}.imageId`);

  const [imageUrlField, , imageUrlFieldHelpers] = useField<DefectImageFormValues['imageUrl']>(
    `${name}.imageUrl`,
  );
  const [imageThumbnailUrlField, , imageThumbnailUrlFieldHelpers] = useField<
    DefectImageFormValues['imageThumbnailUrl']
  >(`${name}.imageThumbnailUrl`);
  const [fileField] = useField<DefectImageFormValues['file']>(`${name}.file`);
  const uploaded = !!imageIdFieldMeta.value;

  useEffect(() => {
    const file = fileField.value;

    if (!file || uploaded) {
      return;
    }

    upload({
      variables: {
        file,
      },
    });
  }, [fileField.value, upload, uploaded]);

  useEffect(() => {
    if (data && !imageIdField.value) {
      imageIdFieldHelpers.setValue(data.uploadImage.id);
      imageUrlFieldHelpers.setValue(data.uploadImage.url);
      imageThumbnailUrlFieldHelpers.setValue(data.uploadImage.url);
    }
  }, [
    data,
    imageIdField.value,
    imageIdFieldHelpers,
    imageUrlFieldHelpers,
    imageThumbnailUrlFieldHelpers,
  ]);

  function renderPreviewSection(handleShowOriginalImageOpen: () => void) {
    if (imageIdField.value) {
      return (
        <ClickableImageThumbnail
          width={56}
          height={40}
          src={imageThumbnailUrlField.value}
          onClick={handleShowOriginalImageOpen}
        />
      );
    }

    return (
      <DefectSectionAttachmentMenu
        loading={loading}
        onUploadImageButtonClick={onUploadImageButtonClick}
        onSelectImageButtonClick={onSelectImageButtonClick}
      />
    );
  }

  return (
    <Box stylex={{ marginTop: 'small-x' }}>
      <ImageUploadWithLabel
        name={name}
        inputContainerClassName={cx(stylex.create({ flexDirection: 'row-reverse' }))}
        labelInputPlaceholder={t('acceptanceReport.defect.meta.label.placeholder')}
        disabled={disabled}
        loading={loading}
        showRemoveButton={showRemoveButton || !!imageIdField.value}
        onRequestRemove={onRemoveButtonClick}
        originalImageUrl={imageUrlField.value}
        previewSection={renderPreviewSection}
      />
    </Box>
  );
}
