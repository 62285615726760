import { useNavigate } from 'react-router-dom';
import { ApolloError, useMutation } from '@apollo/client';
import RiskAssessmentCreateMutation from '../../graphql/RiskAssessmentCreate.mutation';
import { RiskAssessmentFormValues } from '../../../RiskAssessmentForm/RiskAssessmentForm';
import { createRiskAssessmentSaveInput } from '../../../RiskAssessment.utils';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';
import AppModuleProjectDetailQuery from '../../../../Project/app-modules/hooks/useAppModules/graphql/AppModuleProjectDetail.query';

interface UseRiskAssessmentCreateResponse {
  create: (values: RiskAssessmentFormValues, draftProject: boolean) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentSave(
  riskAssessmentId: string,
): UseRiskAssessmentCreateResponse {
  const navigate = useNavigate();

  const [mutate, { loading, error }] = useMutation(RiskAssessmentCreateMutation);

  async function create(values: RiskAssessmentFormValues, draftProject: boolean) {
    if (loading) {
      return;
    }

    await mutate({
      variables: {
        input: createRiskAssessmentSaveInput({
          documentId: riskAssessmentId,
          draft: draftProject,
          values,
        }),
      },
      /**
       * Creating risk assessment directly affects regulation status.
       *
       * TODO: In the future we can maybe use GraphQL subscriptions here instead of refetching app modules,
       *  which is currently not possible
       */
      refetchQueries: [AppModuleProjectDetailQuery],
    });

    navigate(riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }));
  }

  return {
    create,
    error,
    loading,
  };
}
