import { gql, TypedDocumentNode } from '@apollo/client';
import { UpdateProjectStatus, UpdateProjectStatusVariables } from './__generated__/graphql';

export const UPDATE_PROJECT_STATUS: TypedDocumentNode<
  UpdateProjectStatus,
  UpdateProjectStatusVariables
> = gql`
  mutation UpdateProjectStatus($input: UpdateProjectStatusInput!) {
    updateProjectStatus(input: $input) {
      id
      status
    }
  }
`;
