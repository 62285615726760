import { gql, TypedDocumentNode } from '@apollo/client';
import { DriveDocumentRemove, DriveDocumentRemoveVariables } from './__generated__/graphql';

export const REMOVE_DOCUMENT_MUTATION: TypedDocumentNode<
  DriveDocumentRemove,
  DriveDocumentRemoveVariables
> = gql`
  mutation DriveDocumentRemove($input: DriveDocumentRemoveInput!) {
    driveDocumentRemove(input: $input) {
      status
    }
  }
`;
