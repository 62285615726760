import { Badge, Text } from '@hs-baumappe/legacy-ui';
import { useTranslation } from 'react-i18next';

import ProjectDetailDetails from '../ProjectDetailDetails';

import ProjectLogTable from './ProjectLogTable';

export interface Log {
  id: string;
  date?: Date;
  hour: string;
  username: string;
  documentName: string;
  emails?: string[];
}

interface ProjectLogProps {
  viewerLogUnreadCount: number;
  projectId: string;
  initiallyOpen: boolean;
  onRequestReset: () => void;
}

export default function ProjectLog({
  viewerLogUnreadCount,
  projectId,
  initiallyOpen,
  onRequestReset,
}: ProjectLogProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <ProjectDetailDetails
      initiallyOpen={initiallyOpen}
      title={
        <Text variant="title-small" color="muted" component="h3" className="u-margin-bottom-0">
          {t('projectDetail.projectLog.activity')}
        </Text>
      }
      subTitle={
        viewerLogUnreadCount > 0 && (
          <Badge color="error" className="u-margin-left-small u-align-self-center">
            {viewerLogUnreadCount > 99 ? '99+' : viewerLogUnreadCount}
          </Badge>
        )
      }
    >
      <div className="u-display-flex u-margin-top-xsmall">
        <ProjectLogTable projectId={projectId} onCompleted={onRequestReset} />
      </div>
    </ProjectDetailDetails>
  );
}
