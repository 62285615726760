import { gql, TypedDocumentNode } from '@apollo/client';
import {
  DriveViewerPreferences,
  DriveViewerPreferencesVariables,
} from './__generated__/DriveViewerPreferences.query';
import ViewerDrivePreferencesFragment from './ViewerDrivePreferences.fragment';

const DriveViewerPreferencesQuery: TypedDocumentNode<
  DriveViewerPreferences,
  DriveViewerPreferencesVariables
> = gql`
  query DriveViewerPreferences($projectId: ID!) {
    viewer {
      id

      drivePreferences {
        id

        ...ViewerDrivePreferences
      }
    }
  }

  ${ViewerDrivePreferencesFragment}
`;

export default DriveViewerPreferencesQuery;
