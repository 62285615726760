import { parseFromTimeZone } from 'date-fns-timezone';
import {
  AcceptanceReportAttendeeSaveInput,
  AcceptanceReportDefectMetaSaveInput,
  AcceptanceReportDefectSaveInput,
  AcceptanceReportDescriptionSaveInput,
  AcceptanceReportImageInput,
  AcceptanceReportSaveInput,
} from '../../../../globalTypes';
import {
  Acceptance,
  AcceptanceReportFormValues,
  Defect,
  DefectStatus,
} from '../../forms/AcceptanceReportForm/AcceptanceReportFormValues';

const TIMEZONE_CONFIG = { timeZone: 'Europe/Berlin' };

function mapAcceptanceReportDefectValuesToInput(
  formValues: AcceptanceReportFormValues,
  negativeFlowEnabled: boolean,
): AcceptanceReportDefectSaveInput {
  const { defectReferenceFile, defectMeta, defectStatus, warranty, correction } = formValues;

  const warrantyStartDate = warranty?.startDate
    ? parseFromTimeZone(warranty.startDate, TIMEZONE_CONFIG)
    : null;
  const warrantyNote = warranty?.note || null;

  const agreed = defectStatus === DefectStatus.AGREED;
  const defectWarranty = { dueDate: warrantyStartDate, note: warrantyNote };
  const defectCorrection = correction
    ? {
        correction: {
          dueDate: correction?.dueDate
            ? parseFromTimeZone(correction.dueDate, TIMEZONE_CONFIG)
            : null,
          note: correction.note || null,
        },
      }
    : null;

  const warrantyOrCorrection = agreed
    ? defectWarranty
    : negativeFlowEnabled
      ? defectCorrection
      : defectWarranty;

  const metaData = defectMeta.reduce<AcceptanceReportDefectMetaSaveInput[]>(
    (result, currentDefectMeta) => {
      if (currentDefectMeta && (currentDefectMeta.imageId || currentDefectMeta.label)) {
        result.push({
          description: currentDefectMeta.label || '',
          imageId: currentDefectMeta.imageId || undefined,
        } satisfies AcceptanceReportDefectMetaSaveInput);
      }

      return result;
    },
    [],
  );

  return {
    agreed: agreed,
    reference: defectReferenceFile || undefined,
    metaData: !metaData.length ? undefined : metaData,
    ...warrantyOrCorrection,
  };
}

export function createAcceptanceReportSaveInput({
  acceptanceReportId,
  formValues,
  negativeFlowEnabled,
}: {
  acceptanceReportId: string;
  formValues: AcceptanceReportFormValues;
  negativeFlowEnabled: boolean;
}): AcceptanceReportSaveInput {
  const date = parseFromTimeZone(formValues.date, TIMEZONE_CONFIG);

  const attendees = formValues.attendees
    .filter((attendee) => attendee.fullName)
    .map<AcceptanceReportAttendeeSaveInput>((attendee) => ({
      ...attendee,
      fullName: attendee.fullName || '',
      position: attendee.position || undefined,
    }));

  const acceptance = formValues.acceptance === Acceptance.FULL;
  const acceptanceDescription: AcceptanceReportDescriptionSaveInput = {
    text: formValues.acceptanceDescriptionText,
    reference: formValues.acceptanceDescriptionReference,
  };

  const hasDefect = formValues.defect === Defect.HAS_DEFECT;
  const defect = mapAcceptanceReportDefectValuesToInput(formValues, negativeFlowEnabled);

  const images = formValues.images.reduce<AcceptanceReportImageInput[]>(
    (result, { imageId, label }) => {
      if (imageId) {
        result.push({ imageId, label } satisfies AcceptanceReportImageInput);
      }

      return result;
    },
    [],
  );

  return {
    documentId: acceptanceReportId,
    projectName: formValues.name,
    description: formValues.description,
    draftProjectNumber: formValues.projectNumber,
    date,
    clientName: formValues.client,
    contractor: formValues.contractor,
    professions: formValues.toProfessions,
    attendees,
    acceptance,
    acceptanceDescription: !acceptance ? acceptanceDescription : undefined,
    hasDefect,
    defect: hasDefect ? defect : undefined,
    attachImagesToPdf: formValues.attachImagesToPdf,
    images,
  };
}
