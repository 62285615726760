import { Box, Icon, IconButton, Text } from '@hs-baumappe/legacy-ui';
import { AppHeader } from '@hs-baumappe/frontend-kit';
import { useViewer } from '@hs-baumappe/web-auth';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import DocumentDetailBreadcrumbs from '../../../../../components/DocumentDetailBreadcrumbs';
import { CustomFileDocument } from '../../../graphql/__generated__/CustomFileDocument.fragment';
import { CustomFileDocumentProject } from '../../../graphql/__generated__/CustomFileDocumentProject.fragment';
import { DriveDocumentParentFolders } from '../../../../../hooks/useDriveDocumentParentFolders';
import routes from '../../../../routes';
import { getStatusColor } from './DocumentDetailHeader.utils';
import documentRoutes from '../../../documentRoutes';
import { DocumentLocationState } from '../../../document.route.types';

interface DocumentDetailHeaderProps {
  document: CustomFileDocument;
  project: CustomFileDocumentProject;
  parentFolders: DriveDocumentParentFolders;
}

export default function DocumentDetailHeader({
  document,
  project,
  parentFolders,
}: DocumentDetailHeaderProps): JSX.Element {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const location = useLocation<DocumentLocationState>();
  const navigate = useNavigate();

  function handleEditTitleClick() {
    navigate(
      documentRoutes.editTitle.generatePath({
        documentId: document.id,
      }),
      {
        state: {
          backgroundLocation: location,
        } as DocumentLocationState,
      },
    );
  }

  return (
    <>
      <DocumentDetailBreadcrumbs
        projectId={project.id}
        projectNumber={project.projectNumber}
        parentFolders={parentFolders}
      />
      <AppHeader
        title={
          <div className="qa-custom-file-document-title">
            <Box stylex={{ display: 'flex', alignItems: 'center', gap: 'small-3x' }}>
              <Text
                component="h1"
                color="muted"
                variant="body-large"
                stylex={{ marginBottom: '0' }}
              >
                {document.name}
              </Text>
              <IconButton
                size="small"
                icon={<Icon name="create" />}
                onClick={handleEditTitleClick}
                disabled={!document.viewerCanRename}
              />
            </Box>
            <Text color={getStatusColor(document.status)}>
              {t('customFile.documentDetail.header.status', { status: t(document.status) })}
            </Text>
          </div>
        }
        userName={viewer?.email}
        logoutText={t('pageInnerLayout.logOut')}
        logoutURL={routes.logout.generatePath({})}
      />
    </>
  );
}
