import { AcceptanceReportAttendeeRole } from '../../../../globalTypes';
import { ImageUploadWithLabelFormValues } from '../../../../containers/forms/ImageUploadWithLabel';

export enum Acceptance {
  FULL = 'FULL',
  PARTIAL = 'PARTIAL',
}

export enum Defect {
  HAS_DEFECT = 'HAS_DEFECT',
  NOT_DEFECTIVE = 'NOT_DEFECTIVE',
}

export enum DefectStatus {
  AGREED = 'AGREED',
  NOT_AGREED = 'NOT_AGREED',
}

export interface AttendeeFormValues {
  fullName?: string;
  role: AcceptanceReportAttendeeRole;
  position?: string;
}

export type DefectImageFormValues = ImageUploadWithLabelFormValues;

export interface AcceptanceReportFormValues {
  name: string;
  description?: string;
  projectNumber: string;
  date: string;
  client: string;
  contractor: string;
  toProfessions: Array<string>;
  acceptance: Acceptance;
  acceptanceDescriptionText?: string;
  acceptanceDescriptionReference?: string;
  attendees: AttendeeFormValues[];
  defect: Defect;
  defectMeta: DefectImageFormValues[];
  defectReferenceFile?: string;
  defectStatus: DefectStatus;
  warranty?: {
    startDate?: string;
    note?: string;
  };
  correction?: {
    dueDate?: string;
    note?: string;
  };
  attachImagesToPdf: boolean;
  images: ImageUploadWithLabelFormValues[];
}
