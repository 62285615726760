import { Viewer } from '@hs-baumappe/web-auth';
import { SchemaOf } from 'yup';
import { addDays } from 'date-fns';
import { TFunction } from 'i18next';
import yup, { InferObjectToYupShape } from '../../../../yup';
import {
  Acceptance,
  AcceptanceReportFormValues,
  AttendeeFormValues,
  Defect,
  DefectImageFormValues,
  DefectStatus,
} from './AcceptanceReportFormValues';
import { AcceptanceReportAttendeeRole } from '../../../../globalTypes';
import { CreateAcceptanceReportProjectDetail } from '../../../Project/CreateAcceptanceReport/graphql/__generated__/CreateAcceptanceReportProjectDetailQuery';
import { formatDateToDateString } from '../../../../utils/date';
import {
  createEmptyImageWithLabel,
  imageUploadWithLabelValidationSchema,
} from '../../../../containers/forms/ImageUploadWithLabel';

const attendeesValidationSchema: SchemaOf<AttendeeFormValues[]> = yup
  .array<AttendeeFormValues>()
  .defined()
  .default([])
  .min(0)
  .required()
  .of(
    yup
      .object({
        fullName: yup.string().when('role', (role: AcceptanceReportAttendeeRole, schema) => {
          if (role === AcceptanceReportAttendeeRole.HEINRICH_SCHMID) {
            return schema.required();
          }

          if (role === AcceptanceReportAttendeeRole.CLIENT) {
            return schema.required();
          }

          return schema;
        }),
        role: yup
          .mixed<AcceptanceReportAttendeeRole>()
          .oneOf(Object.values(AcceptanceReportAttendeeRole))
          .required(),
        position: yup.string().max(255),
      })
      .required(),
  );

const createDefectMetaValidationSchema = (t: TFunction) =>
  yup
    .array<DefectImageFormValues>()
    .defined()
    .default([])
    .min(0)
    .required()
    .when(['defect', 'defectReferenceFile'], {
      is: (defect: Defect, defectReferenceFile: string) =>
        defect === Defect.HAS_DEFECT && (!defectReferenceFile || defectReferenceFile.trim() === ''),
      then: (schema) =>
        schema.of(
          yup
            .object()
            .shape({
              label: yup.string().required(t('acceptanceReport.defect.meta.label.required')),
              imageId: yup.string(),
              orderId: yup.string(),
            })
            .defined(),
        ),
      otherwise: (schema) =>
        schema.of(
          yup
            .object()
            .shape({
              label: yup.string().when('imageId', {
                is: (imageId: string) => !!imageId,
                then: yup.string().required(),
              }),
              imageId: yup.string(),
              orderId: yup.string(),
            })
            .defined(),
        ),
    });

export const MAX_ACCEPTANCE_DESCRIPTION_TEXT_LENGTH = 300;

export const createAcceptanceReportFormValidationSchema = (
  t: TFunction,
  negativeFlowEnabled: boolean,
): yup.ObjectSchema<InferObjectToYupShape<AcceptanceReportFormValues>> =>
  yup.object().shape<InferObjectToYupShape<AcceptanceReportFormValues>>(
    {
      name: yup.string().required(),
      description: yup.string(),
      projectNumber: yup.string().required(),
      date: yup.date().typeError(t('validation.date.typeError')).required(),
      client: yup.string().max(255).required(),
      contractor: yup.string().max(255).required(),
      toProfessions: yup.array().defined().default([]).of(yup.string().required()),
      acceptance: yup.mixed().oneOf(Object.values(Acceptance)).required(),
      acceptanceDescriptionText: yup
        .string()
        .max(MAX_ACCEPTANCE_DESCRIPTION_TEXT_LENGTH)
        .when(['acceptance', 'acceptanceDescriptionReference'], {
          is: (acceptance: Acceptance, acceptanceDescriptionReference: string) =>
            acceptance === Acceptance.PARTIAL && !acceptanceDescriptionReference,
          then: yup.string().required(t('acceptanceReport.acceptance.descriptionText.required')),
        }),
      acceptanceDescriptionReference: yup
        .string()
        .when(['acceptance', 'acceptanceDescriptionText'], {
          is: (acceptance: Acceptance, acceptanceDescriptionText: string) =>
            acceptance === Acceptance.PARTIAL && !acceptanceDescriptionText,
          then: yup
            .string()
            .required(t('acceptanceReport.acceptance.descriptionReference.required')),
        }),
      attendees: attendeesValidationSchema,
      defect: yup.mixed().oneOf(Object.values(Defect)).required(),
      defectMeta: createDefectMetaValidationSchema(t),
      defectReferenceFile: yup
        .string()
        .max(255)
        .when(['defect', 'defectMeta'], {
          is: (defect: Defect, defectMeta: DefectImageFormValues[]) => {
            const image = defectMeta.filter((f) => f.imageId);
            const label = defectMeta.filter((f) => f.label);
            if (defect === Defect.HAS_DEFECT && image.length === 0 && label.length === 0) {
              return true;
            }
            return false;
          },
          then: yup.string().required(t('acceptanceReport.defect.reference.required')),
        }),
      defectStatus: yup.string().oneOf(Object.values(DefectStatus)).defined(),
      warranty: yup.object().when(['defect', 'defectStatus'], {
        is: (defect: Defect, defectStatus: DefectStatus) => {
          const hasDefect = defect === Defect.HAS_DEFECT;
          const agreed = defectStatus === DefectStatus.AGREED;

          return hasDefect && (agreed || !negativeFlowEnabled);
        },
        then: (schema) =>
          schema
            .shape({
              startDate: yup.date().typeError(t('validation.date.typeError')).required(),
              note: yup.string(),
            })
            .required(),
      }),
      correction: yup.object().when(['defect', 'defectStatus'], {
        is: (defect: Defect, defectStatus: DefectStatus) => {
          const hasDefect = defect === Defect.HAS_DEFECT;
          const notAgreed = defectStatus === DefectStatus.NOT_AGREED;

          return hasDefect && notAgreed && negativeFlowEnabled;
        },
        then: (schema) =>
          schema
            .shape({
              dueDate: yup.date().typeError(t('validation.date.typeError')).required(),
              note: yup.string(),
            })
            .required(),
      }),
      attachImagesToPdf: yup.boolean(),
      images: yup.array().of(imageUploadWithLabelValidationSchema.defined()).defined(),
    },
    // We have to exclude these fields to prevent cyclic dependency to each other
    [
      ['acceptanceDescriptionText', 'acceptanceDescriptionReference'],
      ['defectMeta', 'defectReferenceFile'],
    ],
  );

export function createAcceptanceReportAttendeesInitialValues(
  viewer: Viewer,
  contactPerson?: CreateAcceptanceReportProjectDetail['project']['contactPeople'][number],
): AttendeeFormValues[] {
  return [
    {
      fullName: `${viewer.firstName} ${viewer.lastName}`,
      role: AcceptanceReportAttendeeRole.HEINRICH_SCHMID,
    },
    {
      fullName: contactPerson?.name || '',
      role: AcceptanceReportAttendeeRole.CLIENT,
    },
    {
      fullName: '',
      role: AcceptanceReportAttendeeRole.OTHER,
    },
  ];
}

export function getAcceptanceReportFormInitialValues(
  initialValues?: Partial<AcceptanceReportFormValues>,
): AcceptanceReportFormValues {
  const date = initialValues?.date ? new Date(initialValues.date) : undefined;
  const tomorrow = date ? addDays(date, 1) : undefined;
  const dueDate = tomorrow ? formatDateToDateString(tomorrow) : '';

  const defectMeta =
    !initialValues?.defectMeta || initialValues.defectMeta.length === 0
      ? [createEmptyImageWithLabel()]
      : initialValues.defectMeta;

  return {
    name: '',
    projectNumber: '',
    date: '',
    client: '',
    contractor: '',
    toProfessions: [],
    acceptance: Acceptance.FULL,
    attendees: [],
    defect: Defect.NOT_DEFECTIVE,
    defectStatus: initialValues?.defectStatus ?? DefectStatus.AGREED,
    warranty: {
      startDate: dueDate,
      note: '',
    },
    correction: {
      dueDate: undefined,
      note: '',
    },
    attachImagesToPdf: true,
    defectReferenceFile: '',
    acceptanceDescriptionText: '',
    acceptanceDescriptionReference: '',
    images: [],
    ...initialValues,
    defectMeta,
  };
}
