import { ApolloError, useQuery } from '@apollo/client';
import { Icon, IconButton, useSnackbar } from '@hs-baumappe/legacy-ui';
import cx from 'classnames';
import stylex from '@hs-baumappe/legacy-stylex';
import { useViewer } from '@hs-baumappe/web-auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RiskAssessmentRegulationStatus } from '../../../../../globalTypes';
import { LaunchDarklyFlagSet } from '../../../../../launchdarkly/launchDarklyFlagSet';
import APP_MODULE_REGISTERS, { AppModule, findDriveFolderIdByMimeType } from '../../app-modules';
import AppModuleProjectDetailQuery from './graphql/AppModuleProjectDetail.query';
import RiskAssessmentMissingSignatureNotification from '../../../RiskAssessmentMissingSignatureNotification';
import { DriveSpecialDocumentMimeTypes } from '../../../drive/drive.types';
import { getRiskAssessmentCache, setRiskAssessmentCache } from '../../cache/riskAssessmentCache';

interface UseAppModulesResponse {
  appModules: AppModule[];
  loading: boolean;
  error?: ApolloError;
}

export default function useAppModules(projectId: string): UseAppModulesResponse {
  const { t } = useTranslation();
  const { viewer } = useViewer();
  const snackbar = useSnackbar();
  const flags = useFlags<LaunchDarklyFlagSet>();
  const snackbarIdRef = useRef<string | undefined>(undefined);

  const { data, loading, error } = useQuery(AppModuleProjectDetailQuery, {
    variables: {
      projectId,
      projectDetailRiskAssessmentStatus: flags.projectDetailRiskAssessmentStatus,
    },
    nextFetchPolicy: 'cache-first',
  });

  const project = data?.project;
  const scopes = viewer?.scopes;

  const dismiss = useCallback(() => {
    setRiskAssessmentCache(projectId, { missingSignaturesNotificationDismissed: true });

    if (snackbarIdRef.current) {
      snackbar.close(snackbarIdRef.current);
    }
  }, [projectId, snackbar]);

  useEffect(() => {
    if (!project?.riskAssessmentRegulation || !scopes) return;

    const folderId = findDriveFolderIdByMimeType(
      DriveSpecialDocumentMimeTypes.RISK_ASSESSMENT,
      project.drive.mimeTypeFolderMap,
    );

    if (!folderId) return;

    const riskAssessmentCache = getRiskAssessmentCache(project.id);
    if (riskAssessmentCache?.missingSignaturesNotificationDismissed) return;

    const { riskAssessmentRegulation } = project;
    if (riskAssessmentRegulation.status === RiskAssessmentRegulationStatus.MISSING_SIGNATURE) {
      snackbarIdRef.current = snackbar.open(
        <RiskAssessmentMissingSignatureNotification
          projectId={project.id}
          folderId={folderId}
          riskAssessmentRegulation={project.riskAssessmentRegulation}
          scopes={scopes}
          dismiss={dismiss}
        />,
        {
          autoHideDuration: null,
          type: 'warning',
          className: cx(stylex.create({ alignItems: 'flex-start' })),
          action: () => {
            return (
              <IconButton
                stylex={{ marginTop: 'small-4x' }}
                color="white"
                icon={<Icon name="close" />}
                onClick={dismiss}
              />
            );
          },
        },
      );
    }

    const snackbarId = snackbarIdRef.current;

    return () => {
      if (snackbarId) {
        snackbar.close(snackbarId);
      }
    };
  }, [snackbar, project, scopes, dismiss]);

  const appModules =
    project && scopes
      ? APP_MODULE_REGISTERS.filter((appModule) =>
          appModule.shouldShown(project, scopes, flags),
        ).map<AppModule>((appModule) => {
          const statusProps = appModule.getStatusProps?.({ project, flags, t }) ?? {};

          return {
            id: appModule.id,
            title: t(appModule.titleTranslationKey),
            cornerBadgeText: appModule.cornerBadgeTranslationKey
              ? t(appModule.cornerBadgeTranslationKey)
              : undefined,
            cornerBadgeColor: appModule.cornerBadgeColor,
            imageSrc: appModule.imageSrc,
            className: `qa-${appModule.id}`,
            to: appModule.generatePath(project),
            disabled: appModule.shouldDisabled?.(project, scopes, flags),
            ...statusProps,
          };
        })
      : [];

  return {
    appModules,
    loading,
    error,
  };
}
