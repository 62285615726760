import { gql, TypedDocumentNode } from '@apollo/client';
import {
  UpdateViewerDrivePreferences,
  UpdateViewerDrivePreferencesVariables,
} from './__generated__/UpdateViewerDrivePreferences.mutation';

const UpdateViewerDrivePreferencesMutation: TypedDocumentNode<
  UpdateViewerDrivePreferences,
  UpdateViewerDrivePreferencesVariables
> = gql`
  mutation UpdateViewerDrivePreferences($input: ViewerDrivePreferencesInput!) {
    drivePreferenceUpsert(input: $input) {
      id

      viewPreferences {
        baumappe
        bauarchiv
      }
    }
  }
`;

export default UpdateViewerDrivePreferencesMutation;
