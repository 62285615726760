import { useState } from 'react';
import { createPath, useNavigate } from 'react-router-dom';
import { ApolloError, isApolloError, useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { FormikErrors } from 'formik/dist/types';
import { useSapCredentials } from '@hs-baumappe/sap-credentials';
import RiskAssessmentArchiveMutation from '../../graphql/RiskAssessmentArchive.mutation';
import RiskAssessmentDetailQuery from '../../../graphql/RiskAssessmentDetail.query';
import { DriveDocumentArchiveInput } from '../../../../../globalTypes';
import { riskAssessmentRoutes } from '../../../riskAssessmentRoutes';
import {
  RiskAssessmentDetailAlert,
  RiskAssessmentDetailSearchParams,
} from '../../../RiskAssessmentDetail/RiskAssessmentDetail.utils';
import { getErrorsFromServerError } from '../../../../../apollo/errors';
import { ArchiveFormValues } from '../../../../../containers/ArchiveForm';
import AppModuleProjectDetailQuery from '../../../../Project/app-modules/hooks/useAppModules/graphql/AppModuleProjectDetail.query';

interface UseRiskAssessmentArchiveResponse {
  archive: (values: ArchiveFormValues, formikHelpers: FormikHelpers<ArchiveFormValues>) => void;
  loading: boolean;
  error?: ApolloError;
}

export default function useRiskAssessmentArchive(
  riskAssessmentId: string,
): UseRiskAssessmentArchiveResponse {
  const navigate = useNavigate();
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const { setSapCredentials } = useSapCredentials();
  const [mutation, { loading }] = useMutation(RiskAssessmentArchiveMutation, {
    refetchQueries: [RiskAssessmentDetailQuery],
  });

  async function archive(
    values: ArchiveFormValues,
    formikHelpers: FormikHelpers<ArchiveFormValues>,
  ) {
    if (loading) {
      return;
    }

    setError(undefined);

    try {
      const { data } = await mutation({
        variables: {
          input: {
            id: riskAssessmentId,
            sapUsername: values.username,
            sapPassword: values.password,
          },
        },
        /**
         * Archiving risk assessment directly affects regulation status.
         *
         * TODO: In the future we can maybe use GraphQL subscriptions here instead of refetching app modules,
         *  which is currently not possible
         */
        refetchQueries: [AppModuleProjectDetailQuery],
      });

      if (data) {
        setSapCredentials({ username: values.username, password: values.password });
      }

      const searchParams = new URLSearchParams({
        [RiskAssessmentDetailSearchParams.ALERT]: RiskAssessmentDetailAlert.ARCHIVED,
      });

      navigate(
        createPath({
          pathname: riskAssessmentRoutes.detail.generatePath({ riskAssessmentId }),
          search: searchParams.toString(),
        }),
      );
    } catch (e) {
      if (!(e instanceof Error) || !isApolloError(e)) {
        return;
      }

      const formErrors = getErrorsFromServerError<
        FormikErrors<ArchiveFormValues>,
        DriveDocumentArchiveInput
      >(e, (errors) => {
        return {
          ...errors,
          username: errors.sapUsername,
          password: errors.sapPassword,
        };
      });

      if (formErrors) {
        formikHelpers.setErrors(formErrors);
        return;
      }

      setError(e);
    }
  }

  return {
    archive,
    loading,
    error,
  };
}
