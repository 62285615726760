import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import LogsQuery from './LogsQuery';
import LogContainer, { Log } from '../../../../../../containers/LogContainer';
import logsDateMapper from '../../../../../../containers/LogContainer/LogContainer.utils';

interface Props {
  projectId: string;
  onCompleted?: () => void;
}

export default function ProjectLogTable({ projectId, onCompleted }: Props): JSX.Element | null {
  const [logs, setLogs] = useState<Log[]>([]);
  const [getLogs, { data, refetch, loading }] = useLazyQuery(LogsQuery, {
    variables: {
      projectId,
      limit: 10,
    },
    fetchPolicy: 'network-only',
    onCompleted,
  });

  useEffect(() => {
    getLogs();
  }, [getLogs]);

  useEffect(() => {
    if (!data) {
      return;
    }

    setLogs([...logs, ...data.logs.logs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMoreDataClick = async () => {
    if (!data || !refetch) {
      return;
    }

    await refetch({
      page: data.logs.pagination.current + 1,
      limit: 10,
    });
  };

  const logsDateData = logsDateMapper(logs);
  const hasNextPage = !loading && data && data.logs.pagination.hasNextPage;

  return (
    <LogContainer
      logs={logsDateData}
      loading={loading}
      hasNextPage={hasNextPage}
      onMoreDataClick={handleMoreDataClick}
    />
  );
}
