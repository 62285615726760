import { ChangeEvent, useEffect } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormHelperText,
  Icon,
  Text,
} from '@hs-baumappe/legacy-ui';
import { FormikFormField } from '@hs-baumappe/forms';
import useNavigator from '../../../../hooks/useNavigator/useNavigator';
import { RiskAssessmentEmployees } from './graphql/__generated__/RiskAssessmentEmployees.query';
import { InCharge, InformationValues } from './values';
import validationSchema, { defaultValue } from './form';
import { formatDateToDateString } from '../../../../utils/date';
import { RegionalSecurityManager } from '../../graphql/__generated__/RegionalSecurityManager.fragment';
import { InChargeRole } from '../../../../globalTypes';
import RiskAssessmentFormEmployee from './components/RiskAssessmentFormEmployee';

export interface InformationProps {
  initialValues?: Partial<InformationValues>;
  employees: RiskAssessmentEmployees['employeesForProject'];
  regionalSecurityManagers: RegionalSecurityManager[];
  onSubmit: (values: InformationValues) => void;
  onDirtyStateChange: (dirty: boolean) => void;
  draft: boolean;
}

export default function Information({
  initialValues,
  employees,
  regionalSecurityManagers: allRegionalSecurityManagers,
  onSubmit,
  onDirtyStateChange,
  draft,
}: InformationProps): JSX.Element {
  const { t } = useTranslation();
  const { chromiumBasedBrowser } = useNavigator();
  const autoComplete = chromiumBasedBrowser ? 'chrome-off' : 'off';

  const formik = useFormik({
    validationSchema,
    initialValues: defaultValue(initialValues),
    enableReinitialize: true,
    onSubmit,
  });

  const { values, errors, setFieldValue, handleChange, dirty } = formik;
  const { regionalSecurityManagers, additionalRegionalSecurityManagers } = values;

  useEffect(() => {
    onDirtyStateChange(dirty);
  }, [dirty, onDirtyStateChange]);

  const filledAdditionalSecurityManagers = additionalRegionalSecurityManagers.filter(
    ({ fullName }) => fullName,
  );

  const securityManagersLength =
    regionalSecurityManagers.length + filledAdditionalSecurityManagers.length;

  function handleManagerToggle(manager: InCharge) {
    return function (event: ChangeEvent<HTMLInputElement>) {
      const { checked } = event.currentTarget;

      const alreadySelected = regionalSecurityManagers.some(
        ({ fullName }) => fullName === manager.fullName,
      );

      if (checked && alreadySelected) {
        return;
      }

      const updatedRegionalSecurityManagers = checked
        ? [...regionalSecurityManagers, manager]
        : regionalSecurityManagers.filter(({ fullName }) => fullName !== manager.fullName);

      setFieldValue('regionalSecurityManagers', updatedRegionalSecurityManagers);
    };
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Box className="row">
          <Box className="col col--md-8">
            <Box className="row" stylex={{ marginTop: 'small-x' }}>
              <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                <FormikFormField
                  type="date"
                  name="date"
                  label={t('createAndUpdateRiskAssessmentForm.information.date.label')}
                  onChange={handleChange}
                  max={formatDateToDateString(new Date())}
                />
              </Box>
            </Box>

            <Box className="row">
              <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                <FormikFormField
                  name="projectNr"
                  label={t('createAndUpdateRiskAssessmentForm.information.projectNumber.label')}
                  onChange={handleChange}
                  contentEditable={draft}
                  disabled={!draft}
                />
              </Box>

              <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                <FormikFormField
                  name="customerName"
                  label={t('createAndUpdateRiskAssessmentForm.information.customerName.label')}
                  onChange={handleChange}
                  contentEditable={draft}
                  disabled={!draft}
                />
              </Box>
            </Box>

            <Divider component="hr" role="none" />

            <Box className="row">
              <Box className="col col--sm-12">
                <FormikFormField
                  name="description"
                  label={t('createAndUpdateRiskAssessmentForm.information.description.label')}
                  onChange={handleChange}
                  placeholder={t(
                    'createAndUpdateRiskAssessmentForm.information.description.placeholder',
                  )}
                />
              </Box>

              <RiskAssessmentFormEmployee
                name="securityManager"
                employees={employees}
                fullNameInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.securityManager.label'),
                  placeholder: t(
                    'createAndUpdateRiskAssessmentForm.information.securityManager.placeholder',
                  ),
                }}
                phoneInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.phone.label'),
                  placeholder: t('createAndUpdateRiskAssessmentForm.information.phone.placeholder'),
                }}
              />

              <RiskAssessmentFormEmployee
                name="sideManager"
                employees={employees}
                fullNameInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.siteManager.label'),
                  placeholder: t(
                    'createAndUpdateRiskAssessmentForm.information.siteManager.placeholder',
                  ),
                }}
                phoneInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.phone.label'),
                  placeholder: t('createAndUpdateRiskAssessmentForm.information.phone.placeholder'),
                }}
              />

              <RiskAssessmentFormEmployee
                name="operationManager"
                fullNameInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.operationManager.label'),
                  placeholder: t(
                    'createAndUpdateRiskAssessmentForm.information.operationManager.placeholder',
                  ),
                }}
                phoneInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.phone.label'),
                  placeholder: t('createAndUpdateRiskAssessmentForm.information.phone.placeholder'),
                }}
              />

              <RiskAssessmentFormEmployee
                name="firstAider"
                employees={employees}
                fullNameInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.firstAider.label'),
                  placeholder: t(
                    'createAndUpdateRiskAssessmentForm.information.firstAider.placeholder',
                  ),
                }}
                phoneInputProps={{
                  label: t('createAndUpdateRiskAssessmentForm.information.phone.label'),
                  placeholder: t('createAndUpdateRiskAssessmentForm.information.phone.placeholder'),
                }}
              />
            </Box>

            <Box className="row" stylex={{ marginTop: 'medium' }}>
              <Box className="col col--sm-12">
                <Text color="primary" variant="body-small">
                  {t('createAndUpdateRiskAssessmentForm.securityExpert.text')}
                </Text>
              </Box>
            </Box>

            <Box className="row" stylex={{ marginTop: 'medium' }}>
              <Box className="col col--sm-12">
                <Text variant="label-medium">
                  {t('createAndUpdateRiskAssessmentForm.information.regionalSecurityManager.label')}
                </Text>

                <Box className="row">
                  {allRegionalSecurityManagers.map((manager, index) => {
                    const checked = !!regionalSecurityManagers.find(
                      ({ fullName }) => fullName === manager.fullName,
                    );

                    const disabled = !checked && securityManagersLength === 2;

                    return (
                      <Flex key={index} className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                        <Checkbox
                          label={manager.fullName}
                          variant="filled"
                          checked={checked}
                          onChange={handleManagerToggle({
                            fullName: manager.fullName,
                            phone: manager.phone,
                            role: InChargeRole.REGION_SECURITY_MANAGER,
                          })}
                          disabled={disabled}
                          disableExtraPressableArea
                        />
                      </Flex>
                    );
                  })}
                </Box>

                {errors.firstAider?.phone && (
                  <FormHelperText error className="u-margin-top-2xsmall">
                    {errors.firstAider.phone}
                  </FormHelperText>
                )}
              </Box>
            </Box>
            <Box className="row" stylex={{ marginTop: 'medium' }}>
              <Box className="col col--sm-12">
                <Text color="primary" variant="body-small">
                  {t('createAndUpdateRiskAssessmentForm.alternativeSecurityExpert.text')}
                </Text>
              </Box>
            </Box>

            {additionalRegionalSecurityManagers.map((manager, index) => (
              <Box key={index} className="row" stylex={{ marginTop: 'small-x' }}>
                <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                  <FormikFormField
                    name={`additionalRegionalSecurityManagers[${index}].fullName`}
                    label={t(
                      'createAndUpdateRiskAssessmentForm.information.regionalSecurityManager.label',
                    )}
                    placeholder={t(
                      'createAndUpdateRiskAssessmentForm.information.regionalSecurityManager.placeholder',
                    )}
                    onChange={handleChange}
                    disabled={securityManagersLength === 2 && !manager.fullName}
                    autoComplete={autoComplete}
                  />
                </Box>

                <Box className="col col--sm-6" stylex={{ marginTop: 'small-x' }}>
                  <FormikFormField
                    name={`additionalRegionalSecurityManager[${index}].phone`}
                    label={t('createAndUpdateRiskAssessmentForm.information.phone.label')}
                    placeholder={t(
                      'createAndUpdateRiskAssessmentForm.information.phone.placeholder',
                    )}
                    onChange={handleChange}
                    disabled={securityManagersLength === 2 && !manager.fullName && !manager.phone}
                    autoComplete={autoComplete}
                  />
                </Box>
              </Box>
            ))}
          </Box>

          <Box className="col col--md-4">
            <Box stylex={{ position: 'sticky', top: '0', paddingEnds: 'medium' }}>
              <Button
                type="submit"
                color="primary"
                disabled={securityManagersLength < 1}
                endIcon={<Icon name="arrow-right" />}
                fullWidth
              >
                {t('createAndUpdateRiskAssessmentForm.information.nextStep.button')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
