import { SchemaOf } from 'yup';

import yup from '../../../../yup';
import { DocumentEditTitleFormValues } from './values';

export const validationSchema: SchemaOf<DocumentEditTitleFormValues> = yup.object({
  name: yup.string().max(255).required(),
});

export const documentEditTitleInitialValues = (
  initialValues?: Partial<DocumentEditTitleFormValues>,
): DocumentEditTitleFormValues => {
  return {
    name: '',
    ...initialValues,
  };
};
