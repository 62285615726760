import { LocalStorageCache } from '../../../../utils/cache';
import yup from '../../../../yup';

export type RiskAssessmentCache = {
  missingSignaturesNotificationDismissed: boolean;
};

const riskAssessmentCacheTTL = 24 * 60 * 60 * 1000; // 1 day
const riskAssessmentCacheKey = 'riskAssessment';
const riskAssessmentCache = new LocalStorageCache<RiskAssessmentCache>({
  validationSchema: yup.object({
    missingSignaturesNotificationDismissed: yup.bool().required(),
  }),
});

function createRiskAssessmentCacheKey(projectId: string) {
  return `project:${projectId}:${riskAssessmentCacheKey}`;
}

export function setRiskAssessmentCache(projectId: string, value: RiskAssessmentCache): void {
  const key = createRiskAssessmentCacheKey(projectId);
  return riskAssessmentCache.set(key, value, riskAssessmentCacheTTL);
}

export function getRiskAssessmentCache(projectId: string): RiskAssessmentCache | undefined {
  const key = createRiskAssessmentCacheKey(projectId);
  const cache = riskAssessmentCache.get(key);
  const expiresAt = cache?.expiresAt;

  if (expiresAt && expiresAt < new Date()) {
    riskAssessmentCache.remove(riskAssessmentCacheKey);
    return undefined;
  }

  return cache;
}
