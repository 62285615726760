import { ApolloError, useMutation } from '@apollo/client';

import { REMOVE_DOCUMENT_MUTATION } from './graphql';
import { DocumentType } from '../../../../globalTypes';

export interface Document {
  name: string;
  type: DocumentType;
  id: string;
}

interface UseRemoveDocumentParams {
  remove: () => void;
  error?: ApolloError;
  loading?: boolean;
}

export interface UseRemoveDocumentProps {
  onRemoveSuccess: (id: string) => void;
  document: Pick<Document, 'type' | 'id'>;
}

export default function useRemoveDocument({
  onRemoveSuccess,
  document,
}: UseRemoveDocumentProps): UseRemoveDocumentParams {
  const [mutation, { error, loading }] = useMutation(REMOVE_DOCUMENT_MUTATION, {
    refetchQueries: [
      /**
       * Removing risk assessments directly affect regulation status.
       *
       * TODO: In the future we can maybe use GraphQL subscriptions here instead of refetching app modules,
       *  which is currently not possible
       */
      'AppModuleProjectDetail',
    ],
    update: (cache) => {
      cache.evict({
        id: cache.identify({
          id: document.id,
          __typename: document.type,
        }),
      });
    },
  });

  async function remove() {
    if (loading) {
      return;
    }

    const { id } = document;

    await mutation({
      variables: {
        input: {
          id,
        },
      },
    });

    onRemoveSuccess(id);
  }

  return {
    error,
    loading,
    remove,
  };
}
