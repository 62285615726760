import { ApolloError, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UPDATE_PROJECT_STATUS } from './graphql';
import { ProjectStatus } from '../../../../../globalTypes';
import AppModuleProjectDetailQuery from '../../../app-modules/hooks/useAppModules/graphql/AppModuleProjectDetail.query';

type UseUpdateProjectStatus = {
  updateProjectStatus: (status: ProjectStatus) => void;
  error: ApolloError | undefined;
  loading: boolean;
};

function useUpdateProjectStatus(projectId: string): UseUpdateProjectStatus {
  const [updateProjectStatusMutation, { error, loading }] = useMutation(UPDATE_PROJECT_STATUS);

  const updateProjectStatus = useCallback(
    async (status: ProjectStatus) => {
      await updateProjectStatusMutation({
        variables: {
          input: {
            project: projectId,
            status,
          },
        },
        /**
         * Project status change affects risk assessment regulation status directly.
         *
         * TODO: In the future we can maybe use GraphQL subscriptions here instead of refetching app modules,
         *  which is currently not possible
         */
        refetchQueries: [AppModuleProjectDetailQuery],
      });
    },
    [projectId, updateProjectStatusMutation],
  );

  return {
    updateProjectStatus,
    error,
    loading,
  };
}

export default useUpdateProjectStatus;
