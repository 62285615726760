import routes from '../routes';
import { route } from '../routes.utils';

import { BonusCalculationRouteParams } from './bonusCalculation.route.types';

export default {
  dobnerDetail: route<BonusCalculationRouteParams>(
    `${routes.bonusCalculation.path}/:projectId/dobner`,
  ),
  holtkampDetail: route<BonusCalculationRouteParams>(
    `${routes.bonusCalculation.path}/:projectId/holtkamp`,
  ),
  detailWithoutMode: route<BonusCalculationRouteParams>(
    `${routes.bonusCalculation.path}/:projectId`,
  ),
};
